import React from "react";
import { Link } from "gatsby";
import { createGlobalStyle } from "styled-components";
import { rhythm, scale } from "../utils/typography";

const primaryBackground = "#0089A7";
const primaryText = "#eeeeee";
const headerText = "#FAD689";
const secondaryText = "#00AA90";
const accent = "#b6002b";

const GlobalStyles = createGlobalStyle`
  html{
    background-color: ${primaryBackground};
    font-family: Dosis,sans-serif;

  }

  body{
    font-family: Dosis,sans-serif;
  }

  h1{
    color: ${headerText};
  }

  h2 {
    color: ${primaryText};
  }

  h3{
    color: ${secondaryText};
  }
 
  h4{
    color: ${secondaryText};
  }

  h5{
    color: ${primaryText};
  }

  h6 {
    color: ${primaryText};
  }

  p{
    color: ${primaryText};
  }

  a{
    color: ${secondaryText};
    transition: color 200ms linear;
    &:hover{
      color: ${primaryText};
      text-decoration: underline;
    }
  }

  ul{
    color: ${primaryText};
  }

  li{
    color: ${primaryText};
  }

  small{
    color: ${primaryText};
    padding-bottom: 3px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  blockquote{
    border-color: ${accent};
  }

  footer{
    color: ${primaryText};
  }
`;

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname === rootPath) {
    header = (
      <h1>
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    );
  } else {
    header = (
      <h3>
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    );
  }
  return (
    <>
      <GlobalStyles />
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    </>
  );
};

export default Layout;
